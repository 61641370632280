<template>
   <svg viewBox="0 0 24 24">
      <path
         fill="none"
         stroke="currentColor"
         stroke-linecap="round"
         stroke-linejoin="round"
         stroke-width="2"
         d="M18 6L6 18M6 6l12 12" />
   </svg>
</template>
