
<template>
    <svg viewBox="0 0 24 24">
       <g
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2">
          <circle cx="18" cy="5" r="3" />
          <circle cx="6" cy="12" r="3" />
          <circle cx="18" cy="19" r="3" />
          <path d="m8.59 13.51l6.83 3.98m-.01-10.98l-6.82 3.98" />
       </g>
    </svg>
 </template>
 