<template>
   <svg viewBox="0 0 32 32">
      <path
         fill="none"
         stroke="currentColor"
         stroke-linecap="round"
         stroke-linejoin="round"
         stroke-width="2"
         d="M14 27h4m-4 0l.5-4h3l.5 4m-4 0h-3m7 0h3M5 5h22a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2Z" />
   </svg>
</template>
