<template>
   <svg viewBox="0 0 24 24">
      <g fill="none" stroke="currentColor" stroke-linecap="round">
         <circle cx="12" cy="12" r="10" stroke-width="2" />
         <path
            stroke-width="2"
            d="M7.881 16.244c.493-.427 1.142-.735 1.842-.937A8.308 8.308 0 0 1 12 15c.786 0 1.57.103 2.277.307c.7.202 1.35.51 1.842.937" />
         <circle cx="9" cy="10" r="1.25" fill="currentColor" stroke-width=".5" />
         <circle cx="15" cy="10" r="1.25" fill="currentColor" stroke-width=".5" />
      </g>
   </svg>
</template>
