<template>
   <svg viewBox="0 0 24 24">
      <g
         fill="none"
         stroke="currentColor"
         stroke-linecap="round"
         stroke-linejoin="round"
         stroke-width="2">
         <path d="M4 22h14a2 2 0 0 0 2-2V7l-5-5H6a2 2 0 0 0-2 2v2" />
         <path d="M14 2v4a2 2 0 0 0 2 2h4" />
         <circle cx="6" cy="14" r="3" />
         <path
            d="M6 10v1m0 6v1m4-4H9m-6 0H2m7-3l-.88.88m-4.24 4.24L3 17m6 0l-.88-.88m-4.24-4.24L3 11" />
      </g>
   </svg>
</template>
