<template>
   <svg viewBox="0 0 24 24">
      <g
         fill="none"
         stroke="currentColor"
         stroke-linecap="round"
         stroke-linejoin="round"
         stroke-width="2">
         <circle cx="12" cy="12" r="10" />
         <path d="M12 6v6l4 2" />
      </g>
   </svg>
</template>
