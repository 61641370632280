<template>
   <svg viewBox="0 0 24 24">
      <path
         fill="none"
         stroke="currentColor"
         stroke-linecap="round"
         stroke-linejoin="round"
         stroke-width="2"
         d="m3 3l3 9l-3 9l19-9Zm3 9h16" />
   </svg>
</template>
