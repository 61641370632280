<template>
   <svg viewBox="0 0 24 24">
      <g
         fill="none"
         stroke="currentColor"
         stroke-linecap="round"
         stroke-linejoin="round"
         stroke-width="2">
         <path
            d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7m4 2h6m-3-3v6" />
         <circle cx="9" cy="9" r="2" />
         <path d="m21 15l-3.086-3.086a2 2 0 0 0-2.828 0L6 21" />
      </g>
   </svg>
</template>
