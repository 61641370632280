<template>
   <svg viewBox="0 0 24 24">
      <g
         fill="none"
         stroke="currentColor"
         stroke-linecap="round"
         stroke-linejoin="round"
         stroke-width="2">
         <path d="M12 8V4H8" />
         <rect width="16" height="12" x="4" y="8" rx="2" />
         <path d="M2 14h2m16 0h2m-7-1v2m-6-2v2" />
      </g>
   </svg>
</template>
