<template>
   <svg fill="currentColor" viewBox="0 0 24 24">
      <path
         fill-rule="evenodd"
         clip-rule="evenodd"
         d="M17.25 12H6.75C5.92157 12 5.25 12.6716 5.25 13.5V21C5.25 21.8284 5.92157 22.5 6.75 22.5H17.25C18.0784 22.5 18.75 21.8284 18.75 21V13.5C18.75 12.6716 18.0784 12 17.25 12ZM6.75 10.5C5.09315 10.5 3.75 11.8431 3.75 13.5V21C3.75 22.6569 5.09315 24 6.75 24H17.25C18.9069 24 20.25 22.6569 20.25 21V13.5C20.25 11.8431 18.9069 10.5 17.25 10.5H6.75Z" />
      <path
         fill-rule="evenodd"
         clip-rule="evenodd"
         d="M6.75 6C6.75 3.10051 9.10051 0.75 12 0.75C14.8995 0.75 17.25 3.10051 17.25 6V10.5H15.75V6C15.75 3.92893 14.0711 2.25 12 2.25C9.92893 2.25 8.25 3.92893 8.25 6V10.5H6.75V6Z" />
   </svg>
</template>
