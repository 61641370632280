<template>
   <svg fill="currentColor" viewBox="0 0 24 24">
      <path
         fill="none"
         stroke="currentColor"
         stroke-linecap="round"
         stroke-linejoin="round"
         stroke-width="2"
         d="m6 9l6 6l6-6" />
   </svg>
</template>
