<template>
   <svg fill="currentColor" viewBox="0 0 24 24">
      <path
         fill="none"
         stroke="currentColor"
         stroke-linecap="round"
         stroke-linejoin="round"
         stroke-width="2"
         d="m9 18l6-6l-6-6" />
   </svg>
</template>
